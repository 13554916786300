.navbar {
  width: 100%;
  height: auto; /* Adjust the height as needed */
  padding: 0 20px;
  background: #1a1a1d;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
}

.links {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row; /* Change to row for horizontal layout */
  align-items: center;
  justify-content: center;
  color: #C5B783;
}

.navbar a {
  color: #C5B783;
  text-decoration: none;
  margin: 10px 20px; /* Adjust spacing between links */
  font-size: 25px;
  text-align: center;
}

/* Update media queries and other styles as necessary */
#close .links {
  display: none; /* Hide links when navbar is closed */
}

#open .links {
  display: flex; /* Show links when navbar is expanded */
}
.navbar {
  /* Other styles */
  z-index: 1000; /* High z-index to ensure it's above other content */
  /* Ensure it spans the full width */
  width: 100%;
  /* Other necessary styles */
}
@media only screen and (max-width: 768px) {
  .navbar a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 480px) {
  .navbar a {
    font-size: 12px;
  }
}
