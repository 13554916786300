.projects {
  color: #ffffff; /* White text within .projects */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 40px; /* Top space */
}

.projects .projectTitle {
  text-align: center;
  color: #ffffff;
  font-size: 4.5vw; /* Scalable font size */
  font-weight: bolder;
  margin-bottom: 20px; /* Space below title */
}

.projectList {
  width: 70vw; /* Width adjustment */
  height: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3-column grid */
  gap: 40px; /* Space between grid items */
  justify-content: center;
  align-items: start;
  padding: 0px; /* Inside grid padding */
}

.projectItem {
  border-radius: 15px;
  width: 300px;
  height: 300px;
  box-shadow: 0px 3px 15px rgba(255, 255, 255, 0.2);
  text-align: center;
  transition: box-shadow 0.3s ease-in;
}

.projectItem:hover {
  box-shadow: 0px 3px 15px rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.projectItem .bgImage {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.projectItem h1 {
  font-size: 25px;
}

/* Responsive Adjustments for Larger Screens (e.g., Mac, Windows) */
@media only screen and (min-width: 1501px) {
  .projectList {
    padding: 75px; /* Increased padding for larger screens */
  }
}
/* Responsive Adjustments for Medium Screens */
@media only screen and (max-width: 1500px) {
  .projectList {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    padding: 20px; /* Medium padding for medium screens */
  }

  .projectTitle {
    font-size: 3rem; /* Adjusted font size for medium screens */
  }
}

/* Responsive Adjustments for Smaller Screens (e.g., Mobile) */
@media only screen and (max-width: 800px) {
  .projectList {
    grid-template-columns: 1fr;
    width: 90vw;
    padding: 10px; /* Reduced padding for smaller screens */
  }

  .projectItem {
    width: 80%;
    margin: 0 auto;
  }

  .projectTitle {
    font-size: 2.5rem; /* Adjusted font size for small screens */
  }
}

.skill-line {
  font-size: 1.2rem; /* Scalable font size */
  color: white;
  margin-bottom: 10px; /* Space between lines */
}
