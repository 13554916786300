.container {
    color: #ffffff; /* White text */
    margin: 1rem;
    padding-top: 4rem; /* Adjust space below the nav bar */
    font-family: "Arial", sans-serif; /* Arial font */
    background-color: #000000; /* Black background */
}

.accordion-header, .accordion-button {
    background-color: #333333; /* Dark gray background for headers */
    color: #ffffff; /* White text for headers */
    border-bottom: 1px solid #474747; /* Dark gray border */
}

.accordion-button:not(.collapsed) {
    color: #1abc9c; /* Teal text for active headers */
}

.accordion-item {
    border-color: #474747; /* Dark gray borders */
}

.accordion-body {
    background-color: #000000; /* Black background for body */
    color: #d3d3d3; /* Light gray text for body */
}

/* Rest of the CSS... */
